<template>
    <div id="rooms-info">
        <b-container fluid>
            <div class="room-statistic pb-3 mb-5 border-bottom">
                <b-row>
                    <b-col md="6" class="mb-3" lg="4" v-for="(stats,index) in roomState" :key="index">
                        <div class="d-flex bg-white p-3 rounded align-items-center mb-3 mb-lg-0">
                            <div class="rounded-circle iq-card-icon iq-bg-primary  dark-icon-light mr-3"> <i :class="stats.icon"></i></div>
                            <div class="text-left">
                            <h4>{{stats.title}}</h4>
                            <p class="mb-0">{{roomData[stats.key]}}</p>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div class="sub-rooms">
                <iq-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">Rooms</h4>
                </template>
                <template v-slot:body>
                    <b-row>
                        <b-col sm="6" md="4" class="mb-2" v-for="(room,index) in rooms.data" :key="index">

                            <div class="rooms-card " :style="{backgroundImage: ''}">
                              <div class="img-container w-100 h-100">
                                <img src="https://images.unsplash.com/photo-1611892440504-42a792e24d32?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="">
                              </div>
                              <div class="info bg-white shadow p-2">
                                  <h6 class="font-weight-bold d-flex align-items-center justify-content-between">
                                      Room Number {{room.number}}
                                      <ul class="d-flex align-items-center p-0 m-0">
                                            <li >
                                                <span><i class="fas fa-baby"></i></span>
                                                <span class="mx-1">{{ room.child_number }}</span>
                                            </li >
                                            <li class = "mx-1">
                                                <i class="fas fa-male"></i>
                                                <span class="mx-1">{{ room.audit_number }}</span>
                                            </li>
                                        </ul>
                                  </h6>
                                  <ul class="p-0 m-0">
                                            <li class="py-1 px-2 bg-light border-bottom d-flex align-items-center justify-content-between">
                                                <span><i class="fas fa-receipt"></i> Total Reserve : </span>
                                                <span class="mx-1"> {{ room.total_reserve }}</span>
                                            </li>
                                            <li class="py-1 px-2 bg-light border-bottom d-flex align-items-center justify-content-between">
                                                <span><i class="far fa-eye"></i> Total Views : </span>
                                                <span class="mx-1"> {{ room.tota_views }}</span>
                                            </li>
                                        </ul>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </template>
              </iq-card>
            </div>
        </b-container>
    </div>
</template>
<script>
export default {
  data () {
    return {
      roomData: {},
      roomState: [
        {
          title: 'Room Number',
          icon: 'fas fa-sort-amount-down',
          key: 'number'
        },
        {
          title: 'Total Views',
          icon: 'fas fa-eye',
          key: 'tota_views'
        },
        {
          title: 'Audits',
          icon: 'fas fa-male',
          key: 'audit_number'
        },
        {
          title: 'Childs',
          icon: 'fas fa-child',
          key: 'child_number'
        },
        {
          title: 'Total Reserve',
          icon: 'fas fa-ticket-alt',
          key: 'total_reserve'
        },
        {
          title: 'Bathrooms',
          icon: 'fas fa-sink',
          key: 'bathroom_number'
        },
        {
          title: 'Kitchens',
          icon: 'fas fa-cocktail',
          key: 'kitchen_number'
        }
      ]
    }
  },
  mounted () {
      this.authUserPermissions();
      this.roomData = JSON.parse(localStorage.getItem('room-data'))
  }

}
</script>
<style>

.rooms-card {
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.rooms-card img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.rooms-card .info {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  border-radius: 5px;

}

</style>
